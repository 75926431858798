<template>
  <v-container
    dense
    no-gutters
    class="d-flex align-center py-1 messagerie-list--mini--item rounded overflow-hidden"
    :class="rowClasses"
    v-ripple
  >
    <v-col cols="2" class="text-small px-1">
      <v-chip 
        x-small 
        :color="fiche.fiche.impact.value.id === 4 ? 'error' : 'primary'" 
        dark 
        label 
        :title="($t('actions.ticket-copied'))"
        class="elevation-2"
        @click.stop="
          copyToClipboard(makeUrl(fiche), $nSuccess($t('actions.ticket-copied')))
        ">
          {{ fiche.fiche.ticket }}
          <v-icon v-if="fiche.fiche.phone_be_contacted"
            @click.stop="copyToClipboard(fiche.fiche.phone, $nSuccess($t('actions.phone-copied')))"
            color="white"
            small
            right
            :title="($t('actions.phone-copied'))"
            >mdi-phone-incoming-outline
          </v-icon>
      </v-chip>
    </v-col>
    <v-col cols="2" class="text-small px-1 d-flex flex-column align-center" :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''">
<!--      {{ fiche.fiche.client }}-->
      <v-chip
          x-small
          :color="ColorsUtils.getTypeColor(fiche.fiche.type_demande.value.id)"
          label
          dark
          class="elevation-1 px-1"
      >
        <span class="text-truncate">{{ $t("messagerie.parametrages." + fiche.fiche.type_demande.text) }}</span>
      </v-chip>
    </v-col>
    <v-col :cols="user.superAdministrator ? 3 : 4"
     class="text-small px-1"  :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''"
      >{{  getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules, 0).module.name
      }}<span v-if="fiche.fiche.sous_module">
        -
        {{
          getModuleName(fiche.fiche.module, fiche.fiche.sous_module, 1).sous_module.name
        }}</span
      >
    </v-col>
    <v-col cols="3" class="text-small px-1">
      <v-chip
        x-small
        :color="getColorEtat(fiche.fiche.etat.value.id)"
        label
        dark
        class="font-weight-bold elevation-1 etiquette--fiche text-truncate px-1"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.etat.text) }}
      </v-chip>
    </v-col>
    <v-col v-if="user.superAdministrator && fiche.fiche.appel_client && fiche.fiche.appel_client.length > 0"
      cols="" class="text-small ml-4 mr-1">
      <v-icon
        small
        color="green"
        :title="fiche.fiche.appel_client[fiche.fiche.appel_client.length - 1].user"
      >
        mdi-phone
      </v-icon>
    </v-col>
    <v-col v-else-if="user.superAdministrator && fiche.fiche.appel_client && fiche.fiche.appel_client.length == 0"
      class="text-small ml-4 mr-1"> 
      <v-icon
        small
        color="#ccc"
        title="Pas d'appel client"
      >
        mdi-phone-hangup
      </v-icon>
      </v-col>
      <!-- sinon rien pour ne pas deranger l affichage css des non superadmin -->
    <v-col v-else cols="" class="text-small ml-4 mr-1"></v-col>
    <!-- afficher un icon quand la fiche est suivi -->
    <v-col v-if="user.superAdministrator && fiche.fiche.suivi" cols="" class="text-small mr-1">
    <!-- afficher les droit -->
      <v-icon
        small
        color="#F0F"
        title="Fiche suivie"
      >
        mdi-bookmark-check
      </v-icon>
    </v-col>
    <v-col v-else-if="user.superAdministrator && !fiche.fiche.suivi" cols="" class="text-small mr-1">
      <!-- icon fiche non suivi -->
      <v-icon
        small
        color="#ccc"
        title="Fiche non suivie"
      >
        mdi-bookmark-remove
      </v-icon>
    </v-col>
    <!-- sinon rien pour ne pas deranger l affichage css des non superadmin -->
    <v-col v-else cols="" class="text-small mr-1"></v-col>
    <!-- icon ouvrir dans un nouvel onglet -->
    <v-col 
     cols="" 
     class="text-small"
     >
      <a :href="makeUrl(fiche)"
        target="_blank"
        rel="noopener noreferrer"
        @click.stop
      >
        <v-icon color="primary" size="13" title="Ouvrir dans un nouvel onglet">
          mdi-open-in-new
        </v-icon>
      </a>
    </v-col>

    <!-- <v-spacer></v-spacer> -->
    <!-- <color :color="getTypeColor" class="mr-2" :title="$t('messagerie.parametrages.' + fiche.fiche.type_demande.text)"></color> -->
    <menu-fiche
      :mini="true"
      :fiche="fiche"
      :etats="getMessagerieData().etats"
      :impacts="getMessagerieData().impacts"
      :ordre_priorites="getMessagerieData().ordre_priorites"
      :type_demandes="getMessagerieData().type_demandes"
      :rightsUser="getMessagerieData().rightsUser"
      :tagsPlaneteOnline="getMessagerieData().tagsPlaneteOnline"
      :users="getMessagerieData().users"
      :user="user"
    />
  </v-container>
</template>

<script>
import ColorsUtils from "@/Utils/MessagerieColors";
import moment from "moment";
import Vuex from "vuex";


export default {
  name: "TicketCardMini",
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color"),
    MenuFiche: () => import("@/Components/Views/Hotline/Ticket/TicketMenu"),
  },
  props: {
    fiche: Object,
    selected: Boolean,
    user: Object,
  },
  computed: {
    ...Vuex.mapGetters(["getMessagerieData"]),
    ...Vuex.mapState(["modules"]),
    rowClasses() {
      let classes = {};
      
      classes["selected"] = this.selected;
      classes["urgency-fiche-inset"] = this.fiche.fiche.impact.value.id === 4;
      // classes["primary"] = this.selected;
      classes["lighten-5"] = this.selected;
      classes["more-visibilty"] = this.selected;
      classes[this.$vuetify.theme.dark ? 'notSeenLight' : 'notSeen'] = this.userNotSeen(this.fiche) > 0 ;
      classes["new_fiche"] = this.fiche.fiche.new_fiche && !this.selected && this.userNotSeen(this.fiche) == 0;
      classes["archived"] =
        (this.fiche.fiche.archived || this.fiche.fiche.old_fiche) &&
        !this.selected;
      // Si messages internes et droits hotline
      if (this.fiche.fiche.last_message_interne?.time > this.fiche.fiche.last_message?.time && this.getMessagerieData().rightsUser.hotline && !this.fiche.fiche.last_message_interne.isSeen && !this.selected) {
        classes[!this.$vuetify.theme.dark ? "internal-message" : "internal-message-dark" ] = this.fiche.fiche.last_message_interne && !this.selected;
      } 
      
      return classes;
    },
  },
  data() {
    return {
      ColorsUtils,
      show: false,
    };
  },
  methods: {
    makeUrl(fiche = null) {
      if (fiche) {
        return "https://" + this.getDns() + "/planete-online/app/hotline?ticket=" + fiche._id
      }
    },
    /**
     * Permet de savoir combien de message l'user n'a pas vu
     * @var fiche la fiche
     */
    userNotSeen(fiche) {
      // let found
      // if (this.tmpNotSeen) {
      //   found = this.tmpNotSeen.find(a => a.id == fiche._id)
      // }
      //
      // if (found) {
      //   return found.amount
      // } else {
      //   let r = Math.floor(Math.random() * 5)
      //   r -= 2
      //   if (r < 0) r = 0
      //   this.tmpNotSeen.push({
      //     amount: r,
      //     id: fiche._id
      //   })
      //   return r
      // }
      
      if (fiche.fiche.usersSeen.length == 0) {
        return fiche.fiche.number_messages;
      } else {
        let userSeen = fiche.fiche.usersSeen.find(
          (u) => u.idUser == this.user.id
        );
        if (typeof userSeen != "undefined") {
          return userSeen.nb;
        } else {
          return fiche.fiche.number_messages;
        }
      }
    },
    /**
     * Permet de savoir quel dernier message afficher dans la liste de gauche
     * @var fiche la fiche
     */
    lastMessage(fiche) {
      if (
        typeof fiche.last_message_interne != "undefined" &&
        typeof this.getMessagerieData().rightsUser.hotline != 'undefined'
      ) {
        return fiche.last_message_interne.time > fiche.last_message.time
          ? fiche.last_message_interne
          : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    checkMessageToday(time) {
      let ymdNow = moment().format("MM-DD-YYYY");
      let ymdMsg = moment(time * 1000).format("MM-DD-YYYY");
      if (ymdNow == ymdMsg) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Converti un timestamp en date lisible
     * @var time le timestamp
     */
    dateMessageChat(time, format = null) {
      return moment(time * 1000).format(format);
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + " " + user.lastname.toUpperCase();
    },
    /**
     * Permet d'avoir le nombre d'autres utilisateurs dans la fiche
     * @var fiche La fiche
     */
    getNbUserFiches(fiche) {
      return fiche.fiche.users
        .filter((u) => {
          if (u.id != this.user.id) {
            return u;
          }
          if (u.id != fiche.fiche.userCreate.id) {
            return u;
          }
        })
        .sort(this.compareUsers).length;
    },
    minifyMessage(message) {
      return message.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>

.more-visibilty {
    font-weight: bold;
    color: #e9e9e9;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
    background-color: rgb(139, 135, 135);
}
.messagerie-list--mini--item {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: all 0.2s ease-in-out;
  

  &:hover::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  &.notSeen {
    font-weight: bold;
    color: #151515;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
  }

  &.notSeenLight {
    font-weight: bold;
    color: #e9e9e9;
    border: #fff 1px solid ;
    box-shadow: 0 0 10px 3px #ccc inset;
    background-color: rgb(95, 95, 95);
  }

  &.internal-message {
    font-weight: bold;
    color: #000;
    border: #009688 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
  }
  &.internal-message-dark {
    font-weight: bold;
    color: #fff;
    border: #009688 1px solid;
    box-shadow: 0 0 10px 3px #009688 inset;
  }

  &.selected {
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-primary-base) !important;
    }
  }
  &.new_fiche {
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-warning-base) !important;
    }
  }
  
  &.archived {
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-info-base) !important;
    }
  }
}

.chip-truncate {
  max-width: calc(100% - 16px);

  span {
    line-height: 17px;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
}

</style>
